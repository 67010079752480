.modal {
    position: fixed;
    top: 0;
    left: 0;
    width:100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
  }
  
  .modal-main {
    position:fixed;
    background: #FFFFFF00;
    width: 700px;
    height: auto;
    top:10%;
    left:50%;
    
    transform: translate(-350px, 0%);
  }
  
  .display-block {
    display: block;
  }
  
  .display-none {
    display: none;
  }