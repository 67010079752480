

.MuiButtonBase-root.MuiTab-root {
  background: white;
  border-radius: 12px 12px 0 0;

  
}



.MuiButtonBase-root.MuiTab-root.Mui-selected {
  border-top: 1px solid gray;
  border-left: 1px solid gray;
  border-right: 1px solid gray;
  border-bottom: none; /* not working */
  background-color: white;
  color: #10385A;
  z-index: 10;

  :hover {
    background-color: white;
  }
}
.MuiButtonBase-root.MuiTab-root {
  border-bottom: 1px solid gray;
  z-index: 10;
  background-color: #10385A ;
  color: white;

}

.MuiTabs-indicator {
  display: none;
}